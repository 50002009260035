import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "./LayoutContext";
import * as builder from "../ducks/builder";
import io from "socket.io-client";
import { setNotification } from "src/app/store/data";
import { getCompetitors, getMedia, getOwnResources } from "src/app/store/activeAccount/activeAccountAction";

const socketUrl = process.env.REACT_APP_SOCKET_CONNECTION_URL;
export const socket = io(socketUrl);
/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */
export default function LayoutInitializer({ children, menuConfig, layoutConfig, htmlClassService }) {
    const dispatch = useDispatch();

    const accIdx = useSelector((state) => state.activeAccount.idx);
    const builderState = useSelector(({ builder }) => builder);
    const user = useSelector((state) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    );
    builderState.userData = user;

    useEffect(() => {
        dispatch(builder.actions.setMenuConfig(menuConfig));
    }, [dispatch, menuConfig]);

    useEffect(() => {
        if (layoutConfig.demo !== builderState.layoutConfig.demo) {
            dispatch(builder.actions.setLayoutConfigs(layoutConfig));
        }
    }, [dispatch, builderState, layoutConfig]);

    useEffect(() => {
        dispatch(builder.actions.setHtmlClassService(htmlClassService));
    }, [dispatch, htmlClassService]);

    useEffect(() => {
        socket.on(`imgCrawlComplete/${user.id}`, (data) => {
            dispatch(setNotification({ type: "success", title: "general.success", message: "Media Crawler Completed" }));
            dispatch(getMedia({ idx: accIdx }));
            socket.off(`imgCrawlComplete/${user.id}`);
        });
        socket.on(`urlCrawlComplete/${user.id}`, (data) => {
            dispatch(setNotification({ type: "success", title: "general.success", message: "Crawler Completed" }));
            dispatch(getOwnResources({ idx: accIdx }));
            socket.off(`urlCrawlComplete/${user.id}`);
        });
        socket.on(`competitorCrawlComplete/${user.id}`, (data) => {
            dispatch(setNotification({ type: "success", title: "general.success", message: "Competitor Crawler Completed" }));
            dispatch(getCompetitors({ idx: accIdx }));
            socket.off(`competitorCrawlComplete/${user.id}`);
        });
        socket.on(`newCompetitorCrawlComplete/${user.id}`, (data) => {
            dispatch(setNotification({ type: "success", title: "general.success", message: "New Competitor Crawler Completed" }));
            dispatch(getCompetitors({ idx: accIdx }));
            socket.off(`newCompetitorCrawlComplete/${user.id}`);
        });
        socket.on(`newQualificationProcess/${user.id}`, (data) => {
            if (data.message.status === "successful") {
                // Handle success
                dispatch(setNotification({ 
                    type: "success", 
                    title: "general.success", 
                    message: "Qualification Completed" 
                }));
            } else if (data.message.status === "failed") {
                // Handle failure
                dispatch(setNotification({ 
                    type: "error", 
                    title: "general.qualificationError", 
                    errorType: data?.message?.job?.errors?.length ? "qualification" : "",
                    message: data?.message?.job?.errors || "Qualification process failed due to an unknown error." 
                }));
            }
            socket.off(`newQualificationProcess/${user.id}`);
        });
        // eslint-disable-next-line
    }, [socket]);

    return htmlClassService === builderState.htmlClassServiceObjects ? (
        // Render content when `htmlClassService` synchronized with redux store.
        <>{children}</>
    ) : (
        // Otherwise sow loading splash screen.
        <LayoutSplashScreen />
    );
}
