import React, { useState, useReducer} from "react";
import { useLocation, useHistory } from 'react-router-dom';

export const TourContext = React.createContext();

const TourProvider = (props) => {
    const [stepIndexState, setStepIndexState] = useState(0);
    const [runTour, setRunTour] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const INITIAL_STATE = {
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: [],
        disableOverlayClose: true,
        disableCloseOnEsc: true,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "START":
                return {
                    ...state,
                    stepIndex: 0,
                    run: true,
                    loading: false,
                    key: new Date()
                };
            default:
                return state;
        }
    };
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    const startTour = () => {
        const sideBarToggle = document.body;

        if (location.pathname !== '/dashboard') {
            history.push('/dashboard');
            setTimeout(() => {
                if (sideBarToggle.classList.contains('kt-aside--minimize')) {
                    sideBarToggle.classList.remove('kt-aside--minimize');
                }

                const parent = document.querySelector('.connector-li');
                parent.classList.add('kt-menu__item--open');
                const submenu = parent.querySelector('.kt-menu__submenu');
                if (submenu) {submenu.style.display = 'flex';}

                setStepIndexState(0);
                setRunTour(true);
                dispatch({ type: "START" });
            }, 150);
        } else {
            if (sideBarToggle.classList.contains('kt-aside--minimize')) {
                sideBarToggle.classList.remove('kt-aside--minimize');
            }

            const parent = document.querySelector('.connector-li');
            parent.classList.add('kt-menu__item--open');
            const submenu = parent.querySelector('.kt-menu__submenu');
            if (submenu) {submenu.style.display = 'flex';}

            setStepIndexState(0);
            
            setTimeout(() => {
                setRunTour(true);
                dispatch({ type: "START" });
            }, 100);
        }
    };
    return <TourContext.Provider value={{ stepIndexState, setStepIndexState, tourState, dispatch, startTour, runTour, setRunTour }}>{props.children}</TourContext.Provider>;
};

export default TourProvider;