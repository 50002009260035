import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic";
import "../../_metronic/_assets/sass/pages/wizard/wizard-4.scss";
import { useSelector } from "react-redux";

const Dashboard = lazy(() => import("../pages/home/Dashboard"));

const User = lazy(() => import("../pages/user/UserPage"));

const Billing = lazy(() => import("../pages/billing/BillingPage"));

const Upgrade = lazy(() => import("../pages/billing/BillingPage"));

const GooglePage = lazy(() => import("../pages/google/GooglePage"));

const LinkedinPage = lazy(() => import("../pages/linkedin/LinkedinPage"));

const MetaPage = lazy(() => import("../pages/meta/MetaPage"));

const IntegrationsPage = lazy(() => import("../pages/integrations/IntegrationsPage"));

const DataPage = lazy(() => import("../pages/data/DataPage"));

const Help = lazy(() => import("../pages/support/help"));

const Faq = lazy(() => import("../pages/support/faq"));

const Feedback = lazy(() => import("../pages/support/feedback"));

const wishFunction = lazy(() => import("../pages/support/wishFuction"));

const MediaPage = lazy(() => import("../pages/media/MediaPage"));

const ProductsPage = lazy(() => import("../pages/products/productsPage"));

const UpdatesPage = lazy(() => import("../pages/updates/updatesPage"));

const NotificationsPage = lazy(() => import("../pages/notifications/notificationsPage"));

const EvaluationPage = lazy(() => import("../pages/evaluation/EvaluationPage"));

const ReferPage = lazy(() => import("../pages/refer/ReferPage"));

const AdminPage = lazy(() => import("../pages/admin/AdminPage"));

const BlackListPage = lazy(() => import("../pages/blacklist/BlackListPage"));

const SalesConnectPage = lazy(() => import("../pages/sales-connect/SalesConnectPage"));

const PostWizard = lazy(() => import("../pages/post/PostWizardPage"));

// const TaboolaPage = lazy(() => import("../pages/taboola/TaboolaPage"));

const AdWizard = lazy(() => import("../pages/wizard/adWizardPage"));

// const TiktokPage = lazy(() => import("../pages/tiktok/TiktokPage"));

const Editor = lazy(() => import("../pages/editor/EditorPage"));

const TextAI = lazy(() => import("../pages/textAI/textAIPage"));

const VoiceAI = lazy(() => import("../pages/voiceAI/voiceAIPage"));
// const AdSpace = lazy(() => import("../pages/home/AdSpacePage"));
const AdSpace = lazy(() => import("../pages/home/AdSpace"));

const AdSpaceCalendar = lazy(() => import("../pages/home/AdSpaceCalendar"));

export default function LoggedInRoutes({ fullView }) {
    const user = useSelector((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    );

    // const isClickFraudSubscribed = user.sub && user.sub.line_items &&
    //     user.sub.line_items.some((rec) => (rec.name === "Click Fraud" || rec.name === "Click Trust"));

    const isAdSpaceSubscribed = user.sub && user.sub.line_items &&
        user.sub.line_items.some((rec) => (rec.name === "AdSpace"));

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to={user && user.landingPage ? user.landingPage : "/dashboard"} />
                }

                {/** General */}
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/billing" component={Billing} />
                <Route path="/upgrade" component={Upgrade} />
                <Route path="/user" component={User} />
                <Route path="/refer" component={ReferPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/support/help" component={Help} />
                <Route path="/support/faq" component={Faq} />
                <Route path="/support/feedback" component={Feedback} />
                <Route path="/support/wish" component={wishFunction} />
                {(isAdSpaceSubscribed || user.role === "administrator")&& <Route exact path="/adspace" component={AdSpace} />}
                {user.role === "administrator" && <Route path="/adspace/calendar" component={AdSpaceCalendar} />}
                {fullView && <Route path="/evaluation" component={EvaluationPage} />}
                {fullView && <Route path="/google" component={GooglePage} />}
                {fullView && <Route path="/linkedin" component={LinkedinPage} />}
                {fullView && <Route path="/meta" component={MetaPage} />}
                {fullView && <Route path="/data" component={DataPage} />}
                {fullView && <Route path="/integrations" component={IntegrationsPage} />}
                {fullView && <Route path="/media" component={MediaPage} />}
                {fullView && <Route path="/products" component={ProductsPage} />}
                {fullView && <Route path="/updates" component={UpdatesPage} />}
                {fullView && <Route path="/notifications" component={NotificationsPage} />}
                {/* {fullView && isClickFraudSubscribed && <Route path="/clicktrust" component={BlackListPage} />} */}
                {fullView && <Route path="/clicktrust" component={BlackListPage} />}
                {fullView && <Route path="/sales-connect" component={SalesConnectPage} />}
                {fullView && <Route path="/post" component={PostWizard} />}

                {/* Not In Use */}
                {/* {fullView && <Route path="/taboola" component={TaboolaPage} />} */}

                {fullView && <Route path="/ad" component={AdWizard} />}
                {fullView && <Route path="/editor" component={Editor} />}
                {fullView && <Route path="/ai/text" component={TextAI} />}
                {fullView && <Route path="/ai/assistant" component={VoiceAI} />}
                {/* {fullView && <Route path="/tiktok" component={TiktokPage} />} */}
                {/** Page not found */}
                <Redirect to="/dashboard" />
            </Switch>
        </Suspense>
    );
}
