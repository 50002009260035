export function getUrlExtension(url: string) {
    return url
        ?.split(/[#?]/)[0]
        ?.split(".")
        ?.pop()
        ?.trim();
}

export async function getThumbnailForVideo(videoUrl) {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    video.style.display = "none";
    canvas.style.display = "none";

    // Trigger video load
    await new Promise((resolve, reject) => {
        video.addEventListener("loadedmetadata", () => {
            video.width = video.videoWidth;
            video.height = video.videoHeight;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            // Seek the video to 25%
            video.currentTime = video.duration * 0.25;
        });
        video.addEventListener("seeked", () => resolve(videoUrl));
        video.src = videoUrl;
    });

    // Draw the thumbnailz
    canvas.getContext("2d")?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const imageUrl = canvas.toDataURL("image/png");
    return imageUrl;
}

export function userAgentDetails() {
    const userAgent = navigator.userAgent;
    const userAgentDetails = {
      userAgent: userAgent,
      platform: (function() {
        if (/Win/i.test(userAgent)) return "Windows";
        if (/Mac/i.test(userAgent)) return "MacOS";
        if (/Linux/i.test(userAgent)) return "Linux";
        if (/Android/i.test(userAgent)) return "Android";
        if (/iPhone|iPad/i.test(userAgent)) return "iOS";
        return "Unknown";
      })(),
      device: (function() {
        if (/Mobi|Android/i.test(userAgent)) return "Mobile";
        if (/Tablet|iPad/i.test(userAgent)) return "Tablet";
        return "Desktop";
      })(),
      browser: (function() {
        if (/Chrome/i.test(userAgent)) return "Chrome";
        if (/Firefox/i.test(userAgent)) return "Firefox";
        if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) return "Safari";
        if (/Edge/i.test(userAgent)) return "Edge";
        if (/MSIE|Trident/i.test(userAgent)) return "Internet Explorer";
        return "Unknown";
      })(),
      version: (function() {
        const match = userAgent.match(/(chrome|firefox|safari|edge|msie|trident)\/?\s*(\d+)/i);
        return match ? match[2] : "Unknown";
      })()
    };
    
    return userAgentDetails;
};

export async function getIp () {
    const ipResponse = await fetch("https://api.ipify.org?format=json");
    const ipData = await ipResponse.json();
    return ipData?.ip;
}

export function getAdSpaceIdsByUserId(data, userId) {
    const results = data.filter(item => item.userId === userId);
    return results.map(item => item._id); 
}

export function getTotalCountsByAdSpaceId(data, ids) {
    return data
        .filter(item => ids.includes(item.adSpaceId)) 
        .reduce((total, item) => total + item.counts, 0); 
}

export function mapGoogleApiErrors(errors) {
  if (!Array.isArray(errors) || errors.length === 0) {
      return "An unknown error occurred.";
  }

  const errorMessages = Array.from(
      new Set(errors.map((error) => error.message || "No message provided."))
  );

  // Format messages as a bulleted list
  return `${errorMessages.map((msg) => `<li>${msg}</li>`).join("")}`;
}