export default {
    header: {
        self: {},
        items: [],
    },
    aside: {
        self: {},
        items: [
            {
                title: "dashboard",
                root: true,
                icon: "LayersIcon",
                page: "dashboard",
            },
            {
                section: "basicSection",
            },
            {
                title: "connector",
                root: true,
                icon: "ConnectorIcon",
                bullet: "dot",
                submenu: [
                    {
                        title: "google",
                        root: true,
                        page: "integrations/google",
                        bullet: "dot",
                    },
                    {
                        title: "googleAnalytics",
                        root: true,
                        page: "integrations/analytics",
                        bullet: "dot",
                    },
                    {
                        title: "googleMerchant",
                        root: true,
                        page: "integrations/merchant",
                        bullet: "dot",
                    },
                    {
                        title: "metaAds",
                        root: true,
                        page: "integrations/meta",
                        bullet: "dot",
                    },
                    // {
                    //     title: "taboolaAds",
                    //     root: true,
                    //     page: "integrations/taboola",
                    //     bullet: "dot",
                    // },
                    {
                        title: "linkedin",
                        root: true,
                        page: "integrations/linkedin",
                        bullet: "dot",
                    },
                    {
                        title: "threads",
                        root: true,
                        page: "integrations/threads",
                        bullet: "dot",
                    },
                    // {
                    //     title: "tiktokAds",
                    //     root: true,
                    //     page: "integrations/tiktok",
                    //     bullet: "dot",
                    // },
                ],
            },
            {
                title: "database",
                root: true,
                icon: "Box2Icon",
                bullet: "dot",
                submenu: [
                    {
                        title: "data",
                        root: true,
                        bullet: "dot",
                        page: "data/own-resources",
                    },
                    {
                        title: "productFeed",
                        root: true,
                        bullet: "dot",
                        page: "data/data-feed",
                    },
                    {
                        title: "competitors",
                        root: true,
                        bullet: "dot",
                        page: "data/competitors",
                    },
                ],
            },
            {
                title: "media",
                root: true,
                icon: "MediaIcon",
                page: "media",
            },
            {
                title: "product",
                root: true,
                icon: "Shopping2Icon",
                page: "products",
            },
            {
                title: "searchTerms",
                root: true,
                icon: "PantoneIcon",
                page: "evaluation/search-terms",
            },
            {
                title: "optimizer",
                root: true,
                bullet: "dot",
                icon: "DifferenceIcon",
                submenu: [
                    {
                        title: "googleSpeedTest",
                        page: "https://developers.google.com/speed/pagespeed/insights/?hl=de",
                        external: true,
                    },
                    {
                        title: "googleMobileTest",
                        page: "https://search.google.com/test/mobile-friendly",
                        external: true,
                    },
                    {
                        title: "googleTestMySite",
                        page: "https://www.thinkwithgoogle.com/intl/de-de/feature/testmysite/",
                        external: true,
                    },
                    {
                        title: "googleTrends",
                        page: "https://trends.google.de/trends/?geo=DE",
                        external: true,
                    },
                    {
                        title: "googleGrowMyStore",
                        page: "https://growmystore.thinkwithgoogle.com/intl/de_de",
                        external: true,
                    },
                    {
                        title: "googleMarketFinder",
                        page: "https://marketfinder.thinkwithgoogle.com/intl/de_de/",
                        external: true,
                    },
                    {
                        title: "googleConsumerBarometer",
                        page:
                            "https://www.thinkwithgoogle.com/intl/de-de/insights/kundeneinblicke/das-consumer-barometer-insights-aus-deutschland/",
                        external: true,
                    },
                    {
                        title: "faceBookAdLibrary",
                        page:
                            "https://www.facebook.com/ads/library/?active_status=all&ad_type=political_and_issue_ads&country=DE&media_type=all",
                        external: true,
                    },
                ],
            },
            {
                title: "support",
                root: true,
                icon: "SupportIcon",
                bullet: "dot",
                submenu: [
                    {
                        title: "help",
                        root: true,
                        page: "support/help",
                        bullet: "dot",
                    },
                    // {
                    //     title: "faq",
                    //     root: true,
                    //     page: "support/faq",
                    //     bullet: "dot",
                    // },
                    {
                        title: "feedback",
                        root: true,
                        page: "support/feedback",
                        bullet: "dot",
                    },
                    {
                        title: "wishfunction",
                        root: true,
                        page: "support/wish",
                        bullet: "dot",
                    },
                ],
            },
            {
                section: "advertisingSection",
            },
            {
                title: "googleAds",
                root: true,
                icon: "GoogleAdsIcon",
                submenu: [
                    {
                        title: "googleSearch",
                        root: true,
                        bullet: "dot",
                        icon: "SearchIcon",
                        submenu: [
                            {
                                title: "campaigns",
                                root: true,
                                page: "google/search/campaigns",
                                bullet: "dot",
                            },
                            {
                                title: "adGroups",
                                root: true,
                                page: "google/search/adgroups",
                                bullet: "dot",
                            },
                            {
                                title: "ads",
                                root: true,
                                page: "google/search/ads",
                                bullet: "dot",
                            },
                            {
                                title: "keywords",
                                root: true,
                                page: "google/search/keywords",
                                bullet: "dot",
                            },
                            {
                                title: "extensions",
                                root: true,
                                page: "google/extensions",
                                bullet: "dot",
                            },
                        ],
                    },
                    {
                        title: "googleDisplay",
                        root: true,
                        bullet: "dot",
                        icon: "DisplayIcon",
                        submenu: [
                            {
                                title: "campaigns",
                                root: true,
                                page: "google/display/campaigns",
                                bullet: "dot",
                            },
                            {
                                title: "adGroups",
                                root: true,
                                page: "google/display/adgroups",
                                bullet: "dot",
                            },
                            {
                                title: "ads",
                                root: true,
                                page: "google/display/ads",
                                bullet: "dot",
                            },
                        ],
                    },
                    {
                        title: "smartShopping",
                        root: true,
                        bullet: "dot",
                        icon: "ShoppingIcon",
                        submenu: [
                            {
                                title: "campaigns",
                                root: true,
                                page: "google/shopping/campaigns",
                                bullet: "dot",
                            },
                            {
                                title: "adGroups",
                                root: true,
                                page: "google/shopping/adgroups",
                                bullet: "dot",
                            },
                            {
                                title: "ads",
                                root: true,
                                page: "google/shopping/ads",
                                bullet: "dot",
                            },
                        ],
                    },
                ],
            },
            {
                title: "metaAds",
                root: true,
                icon: "MetaIcon",
                bullet: "dot",
                submenu: [
                    {
                        title: "campaigns",
                        root: true,
                        page: "meta/search/campaigns",
                        bullet: "dot",
                    },
                    {
                        title: "adGroups",
                        root: true,
                        page: "meta/search/adgroups",
                        bullet: "dot",
                    },
                    {
                        title: "ads",
                        root: true,
                        page: "meta/search/ads",
                        bullet: "dot",
                    },
                ],
            },
            // Not In Use
            // {
            //     title: "taboolaAds",
            //     root: true,
            //     icon: "TaboolaIcon",
            //     bullet: "dot",
            //     submenu: [
            //         {
            //             title: "campaigns",
            //             root: true,
            //             page: "taboola/search/campaigns",
            //             bullet: "dot",
            //         },
            //         {
            //             title: "ads",
            //             root: true,
            //             page: "taboola/search/ads",
            //             bullet: "dot",
            //         },
            //     ],
            // },
            {
                title: "linkedinAds",
                root: true,
                icon: "LinkedinIcon",
                bullet: "dot",
                submenu: [
                    {
                        title: "adGroups",
                        root: true,
                        page: "linkedin/adgroups",
                        bullet: "dot",
                    },
                    {
                        title: "campaigns",
                        root: true,
                        page: "linkedin/campaigns",
                        bullet: "dot",
                    },
                    {
                        title: "ads",
                        root: true,
                        page: "linkedin/ads",
                        bullet: "dot",
                    },
                ],
            },
            // {
            //     title: "tiktokAds",
            //     root: true,
            //     icon: "TiktokIcon",
            //     bullet: "dot",
            //     submenu: [
            //         {
            //             title: "campaigns",
            //             root: true,
            //             page: "tiktok/search/campaigns",
            //             bullet: "dot",
            //         },
            //         {
            //             title: "adGroups",
            //             root: true,
            //             page: "tiktok/search/adgroups",
            //             bullet: "dot",
            //         },
            //         {
            //             title: "ads",
            //             root: true,
            //             page: "tiktok/search/ads",
            //             bullet: "dot",
            //         },
            //     ],
            // },
            {
                section: "postSection"
            },
            {
                title: "post.wizard",
                root: true,
                icon: "MegaPhoneIcon",
                page: "post/wizard",
                hide: true,
            },
            {
                title: "post.facebook",
                root: true,
                icon: "MegaPhoneIcon",
                page: "post/facebook",
            },
            {
                title: "post.instagram",
                root: true,
                icon: "MegaPhoneIcon",
                page: "post/instagram",
            },
            {
                title: "post.linkedin",
                root: true,
                icon: "MegaPhoneIcon",
                page: "post/linkedin",
            },
            // {
            //     title: "post.tiktok",
            //     root: true,
            //     icon: "MegaPhoneIcon",
            //     page: "post/tiktok",
            // },
            // {
            //     title: "post.googleMyBusiness",
            //     root: true,
            //     icon: "MegaPhoneIcon",
            //     page: "post/googleMyBusiness",
            // },
            // {
            //     section: "evaluationSection",
            // },
            // {
            //     title: "reportCenter",
            //     root: true,
            //     icon: "LayoutLeftPanel2Icon",
            //     page: "evaluation/reports",
            // },
            // {
            //     title: "controlCenter",
            //     root: true,
            //     icon: "ControlIcon",
            //     page: "evaluation/control",
            // },
            // {
            //     title: "searchTerms",
            //     root: true,
            //     icon: "PantoneIcon",
            //     page: "evaluation/search-terms",
            // },
            // {
            //     section: "usefulSection",
            // },
            // {
            //     title: "optimizer",
            //     root: true,
            //     bullet: "dot",
            //     icon: "DifferenceIcon",
            //     submenu: [
            //         {
            //             title: "googleSpeedTest",
            //             page: "https://developers.google.com/speed/pagespeed/insights/?hl=de",
            //             external: true,
            //         },
            //         {
            //             title: "googleMobileTest",
            //             page: "https://search.google.com/test/mobile-friendly",
            //             external: true,
            //         },
            //         {
            //             title: "googleTestMySite",
            //             page: "https://www.thinkwithgoogle.com/intl/de-de/feature/testmysite/",
            //             external: true,
            //         },
            //         {
            //             title: "googleTrends",
            //             page: "https://trends.google.de/trends/?geo=DE",
            //             external: true,
            //         },
            //         {
            //             title: "googleGrowMyStore",
            //             page: "https://growmystore.thinkwithgoogle.com/intl/de_de",
            //             external: true,
            //         },
            //         {
            //             title: "googleMarketFinder",
            //             page: "https://marketfinder.thinkwithgoogle.com/intl/de_de/",
            //             external: true,
            //         },
            //         {
            //             title: "googleConsumerBarometer",
            //             page:
            //                 "https://www.thinkwithgoogle.com/intl/de-de/insights/kundeneinblicke/das-consumer-barometer-insights-aus-deutschland/",
            //             external: true,
            //         },
            //         {
            //             title: "faceBookAdLibrary",
            //             page:
            //                 "https://www.facebook.com/ads/library/?active_status=all&ad_type=political_and_issue_ads&country=DE&media_type=all",
            //             external: true,
            //         },
            //     ],
            // },
            // {
            //     section: "adconnector",
            // },
            // {
            //     title: "support",
            //     root: true,
            //     icon: "SupportIcon",
            //     bullet: "dot",
            //     submenu: [
            //         {
            //             title: "help",
            //             root: true,
            //             page: "support/help",
            //             bullet: "dot",
            //         },
            //         {
            //             title: "faq",
            //             root: true,
            //             page: "support/faq",
            //             bullet: "dot",
            //         },
            //         {
            //             title: "feedback",
            //             root: true,
            //             page: "support/feedback",
            //             bullet: "dot",
            //         },
            //     ],
            // },
        ],
    },
};
