export const platforms: any = [
    {
        icon: "/media/icons/svg/SocialMedia/googleadsLogo.svg",
        title: "Google Ads",
        name: "googleAds",
        connectLink: "/integrations/google",
    },
    {
        icon: "/media/icons/svg/SocialMedia/meta.svg",
        title: "Facebook",
        name: "facebook",
    },
    {
        icon: "/media/icons/svg/SocialMedia/linkedin.svg",
        title: "LinkedIn",
        name: "linkedin",
    },
    // Not In Use
    // {
    //     icon: "/media/icons/svg/SocialMedia/taboola.svg",
    //     title: "Taboola",
    //     name: "taboola",
    // },
    // {
    //     icon: "/media/icons/svg/SocialMedia/tiktok-icon-small.svg",
    //     title: "TikTok",
    //     name: "tiktok",
    // },
];