/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Modal, Alert, CloseButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IUser } from "src/types/Types";
import { useSelector } from "react-redux";
// import { createMember, updateMember } from "../../crud/members.crud";
import copy from "clipboard-copy";
import { toAbsoluteUrl } from "src/_metronic";
import { ActionModalActivity } from "src/app/utils/activityLog";

export default function ShareAndEarn({ show, onSubmit, onClose, editData }) {
    const { t, i18n } = useTranslation();
    // const [lastStatus, setLastStatus] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [isCopySucceed, setIsCopySucceed] = useState(false);
    // const history = useHistory();
    const user: IUser = useSelector((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    );
    const registerUrl = "https://app.adconnector.com/auth/registration?";
    let videoUrl = "https://adconnector.com/";
    let url = `${registerUrl}ref_by=${user.customMetaId}&lang=${i18n.language}`;


    const onHideModal = () => {
        editData = {};
        setIsCopySucceed(false);
        setErrorMsg("");
        onClose(true);
    }

    const handleClick = (url) => {
        if (i18n.language === "de") {
            videoUrl = "https://adconnector.com/de/";
        }
        window.open(`${url}${videoUrl}`);
    }
    useEffect(() => {
        // eslint-disable-next-line
        url = `${registerUrl}ref_by=${user.customMetaId}&lang=${i18n.language}`;
        if (i18n.language === "de") {
            // eslint-disable-next-line
            videoUrl = "https://adconnector.com/de/";
        }
    }, [i18n, t])

    return (
        <>
            <Modal size="lg" show={show} onHide={onHideModal} >
                <Modal.Body>
                    <Alert show={successMsg ? true : false} variant="success" onClose={() => setSuccessMsg("")} dismissible>
                        {successMsg}
                    </Alert>

                    <Alert show={errorMsg ? true : false} variant="danger" onClose={() => setErrorMsg("")} dismissible>
                        {errorMsg}
                    </Alert>
                    <CloseButton onClick={onHideModal} />
                    <div className="modal-body scroll-y pt-0 pb-15">
                        <div className="mw-lg-600px mx-auto">

                            <div className="mb-13 mt-5 text-center">
                                <h1 className="mb-3">{t('general.share')} &amp; {t('general.earn')}</h1>

                                <div className="text-muted fw-bold fs-5">{t('general.referText')}
                                    {/* <a href="#" className="link-primary fw-bolder">Author Commision</a>. */}
                                </div>

                            </div>
                            <div className="mb-10">

                                <h4 className="fs-5 fw-bold text-gray-800 ml-1">{t('general.shareLinkText')}</h4>

                                <div className="d-flex">
                                    <input id="kt_share_earn_link_input" type="text" className="form-control form-control-solid me-3 flex-grow-1" name="search" value={url} />
                                    <button onClick={() => {
                                        ActionModalActivity("topbar.sub.loveAdc");
                                        copy(url).then(() => {
                                            setIsCopySucceed(true);
                                        })}} id="kt_share_earn_link_copy_button" className="btn btn-light fw-bolder flex-shrink-0 mr-1" data-clipboard-target="#kt_share_earn_link_input">{isCopySucceed ? `${t('general.copiedLinkText')}!!` : `${t('general.copyLinkText')}`} </button>
                                </div>
                            </div>
                            <div className="d-flex">
                                <button onClick={() => {
                                    ActionModalActivity("topbar.sub.loveAdc");
                                    copy(videoUrl).then(() => {
                                        handleClick("http://www.linkedin.com/shareArticle?mini=true&url=");
                                    })}} className="btn btn-light w-100 m-1">
                                    <img alt="Logo" src={toAbsoluteUrl("/media/logos/linkedin-logo.png")} className="h-20px me-3" />LinkedIn</button>
                                <button onClick={() => {
                                    ActionModalActivity("topbar.sub.loveAdc");
                                    copy(videoUrl).then(() => {
                                        handleClick("http://www.facebook.com/sharer/sharer.php?u=");
                                    })}} className="btn btn-light w-100 mx-6 m-1">
                                    <img alt="Logo" src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/social-logos/facebook.svg" className="h-20px me-3" />Facebook</button>
                                <button onClick={() => {
                                    ActionModalActivity("topbar.sub.loveAdc");
                                    copy(videoUrl).then(() => {
                                        handleClick("https://api.whatsapp.com/send?text=");
                                    })}} className="btn btn-light w-100 m-1">
                                    <img alt="Logo" src={toAbsoluteUrl("/media/logos/WhatsAppLogo.png")} className="h-25px me-3" />WhatsApp</button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                {/* <Modal.Footer> */}
                {/* <Button onClick={onHideModal} color="primary">
                        {t('member.close')}
                    </Button> */}
                {/* <Button
                        type="submit"
                        variant="primary"
                        className={`btn btn-label-brand btn-bold ${Form.isSubmitting ? "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--brand custom-loading-icon" : ""}`}
                        onClick={Form.submitForm}
                        disabled={Form.isSubmitting}
                    >
                        {t('member.submit')}
                    </Button> */}
                {/* </Modal.Footer> */}
            </Modal>
        </>
    )
}