/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import { LayoutContextConsumer } from "../LayoutContext";
import * as builder from "../../ducks/builder";
// import QuickActions from "./components/QuickActions";
import { useTranslation } from "react-i18next";
import CustomDateRangePicker from "../../../app/partials/layout/CustomDateRangePicker";
import NewMember from "../../../app/partials/layout/NewMember";
import ShareAndEarn from "../../../app/partials/layout/Share&EarnModal";
import { ReactComponent as NewUserIcon } from "../../../_metronic/layout/assets/layout-svg-icons/new-user.svg";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "src/app/store/data";
import { Link } from "react-router-dom";
import { ViewModalActivity } from "src/app/utils/activityLog";
// import useWindowDimensions from './../../utils/useWindowDimensions';
import { ReactComponent as InfoIcon } from "../../../_metronic/layout/assets/layout-svg-icons/info.svg";
import InfoModal from '../../../app/partials/layout/InfoModals';
import { isEmptyObject } from "jquery";
// import { KTSVG } from "src/_metronic/_helpers/components/KTSVG";
import { PlatformContext } from "../../../app/context/PlatformContext";
import { TourContext } from "src/app/context/TourContext";
import { fetchAccount } from "src/app/store/platform/platform.actions";
import { platforms } from "src/_metronic/layout/sub-header/SubHeaderHelper";

export function SubHeader({
  subheaderCssClasses,
  subheaderContainerCssClasses,
  subheaderMobileToggle
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [isShareAndEarnOpen, setShareAndEarnOpen] = useState(false);
  const isParent = useSelector((state) => state.auth.user && state.auth.user.parent ? true : false);
  const user = useSelector((state) =>
    state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
  );
  const { updateAdType } = useContext(PlatformContext);

  // const sideBarToggle = useSelector((state) => state.builder.htmlClassServiceObjects.config.aside.self.minimize.default);
  // const { width } = useWindowDimensions();
  const [currentTitle, setCurrentTitle] = useState("");
  const [show, setShow] = useState(false);
  const { startTour } = useContext(TourContext);

  useEffect(() => {
    dispatch(fetchAccount());
  }, [dispatch]);

  const handleClose = () => {
    setCurrentTitle("");
    setShow(false);
  }
  const handleShow = (title) => {
    setCurrentTitle(`aside.${title}`);
    setShow(true);
  }
  const onNewMember = () => {
    if (isParent) {
      dispatch(setNotification({ type: "info", title: 'general.info', message: 'general.noPermission' }));
      return;
    }
    ViewModalActivity("topbar.sub.newMember");
    setModal(true)
  }

  const handlePlatform = (type) => {
    updateAdType(type);
  };

  const accData = useSelector((state) => state.platforms.account);

  const checkAccountData = (platform) => {
    const data = accData.find((acc) => acc.platform === platform && acc.account);
    if (data) return data?.account?.message ? false : true;
    else return false;
  };

  const logoStyleBox = {
    backgroundColor: "rgba(77, 89, 149, 0.06)",
    borderRadius: "4px",
    textAlign: "center",
    width: "41px",
    height: "36px",
    // padding: "0.65rem 0.85rem",
    marginRight: "0.5rem",
    cursor: "pointer"
  }

  const boxSpacing ={
    marginRight: "0.25rem"
  }

  return (
    <div
      id="kt_subheader"
      className={`kt-subheader ${subheaderCssClasses} kt-grid__item set-height subHeader-custom header-menu-wrapper-on`}
    >
      <div className={`kt-container ${subheaderContainerCssClasses}`}>
        <div className="d-flex justify-content-center align-items-center">
          {/* <span
            style={logoStyleBox}
            onClick={() => handlePlatform('google')}
          > */}
          {/* <KTSVG
              path="/media/icons/svg/SocialMedia/googleadsLogo.svg"
              className="svg-icon-1 svg-icon-gray-600"
            /> */}
          {/* <img src="/media/icons/svg/SocialMedia/googleadsLogo.svg" alt="linkedin-logo" style={{ width: '1.5rem', height: '1.5rem' }} />
          </span>
          <span
            style={logoStyleBox}
            onClick={() => handlePlatform('facebook')}
          > */}
          {/* <KTSVG
              path="/media/icons/svg/SocialMedia/meta.svg"
              className="svg-icon-1 svg-icon-gray-600"
            /> */}
          {/* <img src="/media/icons/svg/SocialMedia/meta.svg" alt="linkedin-logo" style={{ width: '1.5rem', height: '1.5rem' }} />
          </span>
          <span
            style={logoStyleBox}
          >
            <img src="/media/icons/svg/SocialMedia/linkedin.svg" alt="linkedin-logo" style={{ width: '1.2rem', height: '1.2rem', marginTop: '-2px' }} /> */}
          {/* <KTSVG
              path="/media/icons/svg/SocialMedia/linkedin.svg"
              className="svg-icon-1 svg-icon-gray-600"
            /> */}
          {/* </span>
          <span
            style={logoStyleBox}
          > */}
          {/* <KTSVG
              path="/media/icons/svg/SocialMedia/taboola.svg"
              className="svg-icon-1 svg-icon-gray-600"
            /> */}
          {/* <img src="/media/icons/svg/SocialMedia/taboola.svg" alt="linkedin-logo" style={{ width: '1.5rem', height: '1.5rem' }} />
          </span>
          <span
            style={logoStyleBox}
          >
            <img src="/media/icons/svg/SocialMedia/tiktok-icon-small.svg" alt="Twitter-logo" style={{ width: '16px', height: '16px' }} /> */}
          {/* <KTSVG
              path="/media/icons/svg/SocialMedia/tiktok-icon-small.svg"
              className="svg-icon-1 svg-icon-gray-600"
            /> */}
          {/* </span> */}
          {platforms.map((platform, i) => (
            // !checkAccountData(platform.name) ? "" : (
              <span
                key={i}
                // style={logoStyleBox}
                style={{
                  ...logoStyleBox,
                  border: checkAccountData(platform.name)  ? '1px solid #5385e1' : 'none',
                  padding: checkAccountData(platform.name)  ? '0.65rem' : '0.65rem 0.85rem',
                  cursor: checkAccountData(platform.name)  ? 'pointer' : 'default',
                }}              
                // onClick={() => handlePlatform(platform.name)}
                onClick={platform.name === 'googleAds' || platform.name === 'facebook'
                  ? () => handlePlatform(platform.name)
                  : undefined}
              >
                <img src={platform.icon} alt={platform.title} 
                // style={{ width: '1.5rem', height: '1.5rem' }} 
                style={platform.name === 'linkedin' 
                  ? { width: '1.2rem', height: '1.2rem', marginTop: '-2px' }
                  : { width: '1.5rem', height: '1.5rem' }
                } 
                />
              </span>
              // )
          ))}
        </div>

        <div className="kt-subheader__main">
          {subheaderMobileToggle && (
            <button
              className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <LayoutContextConsumer>

            {({ subheader: { title } }) => (
              <>
                {/* <h3 style={title === "chatSubHeaderTitle" ? { padding: 0 } : {}} className="kt-subheader__title"> {title ? t(`aside.${title}`) : ""}</h3> */}
                {title === "chatSubHeaderTitle" ?
                  <div className="mt-1" onClick={() => handleShow(title)}>
                    <InfoIcon />
                  </div> : null}
              </>

            )}
          </LayoutContextConsumer>
        </div>

        <div className="kt-subheader__toolbar">
          <div className="kt-subheader__wrapper">
            <button className="btn kt-subheader__btn-primary tutorial-class" onClick={startTour} style={boxSpacing}>
            {t('Tour.buttontext.startTour')}
            </button>

            <Link to="/ai/assistant" className="btn kt-subheader__btn-primary" style={boxSpacing}>
              {t('aside.voiceAI')}
            </Link>

            <Link
              to={{ pathname: '/support/help', state: { isTicket: true } }}
              className={isEmptyObject(user.sub) ? "btn support-btn subHeaderButton support-btn2" : "btn support-btn support-class"}
              style={boxSpacing}
            >
            {t('support.help.support')}
            </Link>

            {/* <Link to="/support/wish" className="btn kt-subheader__btn-primary" style={boxSpacing}>
              {t('support.wish.buttonText')}
            </Link> */}

            <CustomDateRangePicker />

            <Link style={boxSpacing}
              to="/upgrade"
              className={isEmptyObject(user.sub) ? "btn support-btn subHeaderButton support-btn2" : "btn support-btn upgrade-class"}
            >
            {t('partials.userProfile.upgradeLabel')}
            </Link>

            {/* <QuickActions /> */}

            <button style={boxSpacing} onClick={() => onNewMember()} type="button" className="btn kt-subheader__btn-primary">{t(`topbar.sub.newMember`)} &nbsp;
              <NewUserIcon
                className={isEmptyObject(user.sub) ? "subHeaderUserSvgIcon kt-svg-icon kt-svg-icon--sm" : "kt-svg-icon kt-svg-icon--sm"}
              />
            </button>

            <InfoModal show={show} onHide={handleClose} title={currentTitle} message="chat.infoText" message2={undefined} />
            <NewMember show={modal} onSubmit={() => setModal(false)} onClose={() => setModal(false)} />
            <ShareAndEarn show={isShareAndEarnOpen} onSubmit={() => setShareAndEarnOpen(false)} onClose={() => setShareAndEarnOpen(false)} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  })
});

export default withRouter(connect(mapStateToProps)(SubHeader));
