/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { IUser } from "src/types/Types";
import { useTranslation } from "react-i18next";
import { getMe } from "src/app/crud/auth.crud";
import { updateMe as updateMeAction } from "../../store/auth";
import { isEqual } from "lodash";
import * as SvgIcons from "src/app/partials/content/SVGIcons";
import { isEmptyObject } from "jquery";


export default function UserProfile() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user: IUser = useSelector((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    );

    const userRef = useRef<IUser | null>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            getMe().then(response => {
                if (response && response.data) {
                    if (isEqual(userRef.current, response.data)) return;
                    dispatch(updateMeAction(response.data));
                }
            });
        }, 120000);
        return () => clearInterval(interval);
    }, [dispatch]);

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    return (
        <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
            <Dropdown.Toggle
                as={HeaderDropdownToggle}
                id="dropdown-toggle-user-profile"
            >
                <div className="kt-header__topbar-user">
                    {
                        user.profilePic
                            ?
                            <img alt="profile" src={`${user.profilePic}?v=${Date.now()}`} style={{ maxHeight: 24 }} />
                            :
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                className={isEmptyObject(user.sub)
                                    ? "kt-svg-icon topbarSvgUserPIcon kt-svg-icon g"
                                    : "kt-svg-icon"
                                }
                            >
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                    <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                                </g>
                            </svg>
                    }
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                <div
                    className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                    style={{
                        // backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
                        background: "linear-gradient(150deg, #46bef7 0%, #5385e1 100%)"
                    }}
                >
                    {/* <div className="kt-user-card__avatar">
                        <Link to={`/user/profile`} className="kt-notification__item">
                            {
                                user.profilePic
                                    ?
                                    <img alt="profile" src={`${user.profilePic}?v=${Date.now()}`} />
                                    :
                                    <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                                        {(user.billing.company.substring(0, 1) + user.billing.company.substring(1, 2)).toUpperCase()}
                                    </span>
                            }
                        </Link>
                    </div> */}


                    <div className="kt-user-card__name">{user.billing.company}</div>
                </div>
                <div className="kt-notification">
                    <Link to={`/user/profile`} className="kt-notification__item">
                        <div className="kt-notification__item-icon">
                            <SvgIcons.DropDownProfileIcon />
                        </div>
                        <div className="kt-notification__item-details">
                            <div className="kt-notification__item-title kt-font-bold">
                                {t('partials.userProfile.userProfile')}
                            </div>
                            <div className="kt-notification__item-time">
                                {t('partials.userProfile.profileDesc')}
                            </div>
                        </div>
                    </Link>
                    <Link to={`/user/members`} className="kt-notification__item">
                        <div className="kt-notification__item-icon">
                            <SvgIcons.DropDownUserIcon />
                        </div>
                        <div className="kt-notification__item-details">
                            <div className="kt-notification__item-title kt-font-bold">
                                {t('partials.userProfile.membersLabel')}
                            </div>
                            <div className="kt-notification__item-time">
                                {t('partials.userProfile.membersDesc')}
                            </div>
                        </div>
                    </Link>
                    <Link to={`/billing`} className="kt-notification__item">
                        <div className="kt-notification__item-icon">
                            <SvgIcons.DropDownBillingIcon />
                        </div>
                        <div className="kt-notification__item-details">
                            <div className="kt-notification__item-title kt-font-bold">
                                {t('partials.userProfile.billingLabel')}
                            </div>
                            <div className="kt-notification__item-time">
                                {t('partials.userProfile.billingDesc')}
                            </div>
                        </div>
                    </Link>
                    <div className="kt-notification__custom d-flex justify-content-between">
                        <Link
                            to="/logout"
                            className="btn btn-clean btn-sm btn-bold"
                            style={{background: '#f2f3f7', color: '#959cb6', border: 0}}
                        >
                            {t('partials.userProfile.logoutLabel')}
                        </Link>
                        <Link
                            to="/upgrade"
                            className="btn btn-label-brand btn-sm btn-bold bg-blue-gradient-hover"
                            style={{background: 'linear-gradient(150deg, #46bef7 0%, #5385e1 100%)',
                                border: 0,
                                color: '#fff',
                                fontWeight: 500}}
                        >
                            {t('partials.userProfile.upgradeLabel')}
                        </Link>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown >
    );
};
